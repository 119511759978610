<template>
  <div class="box">
    <h1>PRIVACY POLICY</h1>
    <p>
      Your privacy is important to us. The purpose of this Privacy Policy is to
      inform you of how TOS PTE. LTD. (Singapore Company Registration Number:
      201906913K) (<b>“TOS”</b>, <b>“we”</b>, <b>“us”</b> or <b>“our”</b>)
      manages your Personal Data (as defined below) in accordance with the
      Personal Data Protection Act 2012 (<b>“PDPA”</b>).
    </p>
    <p>
      TOS LIFE is a mobile-based platform that is integrated with functions such
      as facility booking, feedback report, announcements, payment of
      maintenance fees, and all other services and functions that we may
      introduce from time to time (<b>“Platform”</b>). This Platform is owned
      and operated by us, and can be assessed by residents of the condominium
      through the TOS LIFE application (<b>“App”</b>) that you can download from
      the Apple App Store or Android Play Store (whichever is applicable).
    </p>
    <p>
      Please read this Privacy Policy carefully. By registering an Account (as
      defined below) to use the Platform, using and signing up for any products
      and/or services made available on the Platform (<b
        >“Products and/or Services”</b
      >), and/or submitting any Personal Data to us thereon, you acknowledge and
      consent to the terms of this Privacy Policy.
    </p>
    <p>
      This Privacy Policy supplements but does not supersede nor replace any
      other consents you may have previously provided to us nor does it affect
      any rights which we may have at law in connection with the collection, use
      and/or disclosure of your Personal Data. We may from time to time update
      this Privacy Policy to ensure that this Privacy Policy is consistent with
      our future developments, industry trends and/or any changes in legal or
      regulatory requirements. Subject to your rights at law, you agree to be
      bound by the prevailing terms of the Privacy Policy as updated from time
      to time on the App.
    </p>

    <h2>1.Collection of Personal Data</h2>
    <li>
      1.1.In this Privacy Policy, “Personal Data” refers to any data and/or
      information about you from which you can be identified either (a) from
      that data; or (b) from that data and other information to which we have or
      are likely to have access. Depending on the nature of your interaction
      with us, the Personal Data that we may collect from you include:
    </li>
    <p>
      (a)your name, NRIC, passport or other identification number, telephone
      number(s), mailing address, email address and any other information
      relating to you which you have provided us, or in other forms of
      interaction with you;
    </p>
    <p>
      (b)your payment related information, such as your bank account or credit
      card information;
    </p>
    <p>
      (c)information about your use of our App, the Platform, Products and/or
      Services, but only to the extent that we can identify you from such
      information; and
    </p>
    <p>
      (d)information about your usage of and interaction with our App, the
      Platform, Products and/or Services.
    </p>

    <h2>2.Use of Personal Data for Business Purposes</h2>
    <li>
      2.1.We may use your Personal Data for our business purposes, such as:
    </li>
    <p>
      (a)to register your account with us to use the Platform
      (<b>“Account”</b>);
    </p>
    <p>
      (b)to verify and authenticate your identity, update our records and
      generally maintain your Account with us;
    </p>
    <p>(c)providing and delivering our Products and/or Services to you;</p>
    <p>
      (d)assessing and processing your registration forms, applications,
      instructions or requests from you in relation to our Products and/or
      Services;
    </p>
    <p>
      (e)monitoring the Products and/or Services provided by or made available
      to you through us;
    </p>
    <p>(f)carrying out research, planning and/or statistical analysis;</p>
    <p>
      (g)managing our infrastructure, business operations and complying with
      internal policies and procedures;
    </p>
    <p>
      (h)communicating with you, including providing you with updates on
      changes, promotions, upcoming events and/or news to our Products and/or
      Services;
    </p>
    <p>
      (i)providing you with the customer service in relation the Products and/or
      Services, including responding to your queries and/or feedback;
    </p>
    <p>(j)addressing or investigating any complaints, claims or disputes;</p>
    <p>
      (k)verifying your identity for the purposes of providing you our Products
      and/or Services;
    </p>
    <p>
      (l)facilitating any proposed or confirmed merger, acquisition or business
      asset transaction involving TOS and/or its related corporations, or
      corporate restructuring process;
    </p>
    <p>
      (m)in connection with performance of duties when seeking consultancy or
      professional advice, including legal advice; and/or
    </p>
    <p>
      (n)complying with all applicable laws, regulations, rules, directives,
      orders, instructions, guidance and requests from any local or foreign
      authorities, including regulatory, governmental, tax and law enforcement
      authorities or other authorities.
    </p>

    <h2>3.Use of Personal Data for Marketing Purposes</h2>
    <li>
      3.1.We may use your Personal Data for marketing purposes to offer you our
      Products and/or Services, including special offers or promotions that may
      be of interest to you or for which you may be eligible. Such marketing
      messages may be sent to you in various modes including but not limited to
      electronic mail, direct mailers, short message service, telephone calls
      and/or other mobile messaging services (such as WhatsApp). In doing so, we
      will comply with the applicable laws and regulations, including the PDPA
      and other applicable data protection and privacy laws, such as the
      European Union General Data Protection Regulation (“GDPR”) (where
      applicable).
    </li>
    <li>
      3.2.In respect of sending telemarketing messages to your Singapore
      telephone number via short message service, telephone calls and/or other
      mobile messaging services (such as WhatsApp), please be assured that we
      shall only do so if:
    </li>
    <p>
      (a)you have provided your clear and unambiguous consent in writing or
      other recorded form for us to do so; and
    </p>
    <p>
      (b)you have not indicated to us in our ongoing relationship that you do
      not wish to receive telemarketing messages sent to your Singapore
      telephone number and you have not registered that number with the National
      Do Not Call Registry.
    </p>
    <li>
      3.3.We may, as part of our ongoing relationship with you, send marketing
      messages to you. You may at any time request that we stop contacting you
      for marketing purposes.
    </li>

    <h2>4.Disclosure and Sharing of Personal Data</h2>
    <li>
      4.1.Your Personal Data will not be disclosed by us to any third party,
      unless we have your consent, or we are under a legal obligation or duty,
      to do so, except for disclosure to the following individuals or entities
      which you hereby agree to:
    </li>
    <p>
      (a)our related and affiliated companies (including those in different
      jurisdictions);
    </p>
    <p>(b)government authorities or regulators;</p>
    <p>
      (c)our professional advisers, including without limitation our lawyers,
      accountants and auditors;
    </p>
    <p>
      (d)our third party service providers and sub-contractors (including those
      who provide payment, administrative or other services to us in connection
      with the operation of our the App and/or the Platform) or our data
      processors; and any agent acting on our behalf.
    </p>
    <li>
      4.2.We will ensure that the use of your Personal Data will be kept to what
      is adequate, relevant and limited to what is necessary for us to carry out
      the purposes set out above. Please be assured that when we disclose your
      Personal Data to such parties, we require them to ensure that any Personal
      Data disclosed to them are kept confidential and secure.
    </li>
    <li>
      4.3.We wish to emphasise that we do not sell Personal Data to any third
      parties and we shall remain fully compliant of any duty or obligation of
      confidentiality imposed on us under the applicable agreement(s) and/or
      terms and conditions that govern our relationship with you or any
      applicable law.
    </li>
    <li>
      4.4.We are not likely to transfer, store, process and/or deal with your
      Personal Data outside Singapore, but if we do so (to the limit necessary
      for us to carry out the purposes set out above), we will comply with the
      PDPA and other applicable data protection and privacy laws, such as the
      GDPR.
    </li>

    <h2>5.Cookies and Related Technologies</h2>
    <li>
      5.1.Our App uses cookies. A cookie is a small text file placed on your
      computer or mobile device when you assess the Platform. Cookies collect
      information about users, such as their Internet protocol (IP) address, and
      how they navigate within the website. We use cookies and other
      technologies to facilitate your internet sessions, offer you Products
      and/or Services according to your preferred settings, track use of the
      Platform and to compile statistics about activities carried out by you on
      the Platform.
    </li>
    <li>
      5.2.A pixel tag, also known as a web beacon, is an invisible tag placed on
      the Platform. Pixel tags are usually used in conjunction with cookies and
      are used to monitor the behaviour of users assessing the Platform.
    </li>
    <li>
      5.3.You may set up your App to block cookies which will in turn disable
      the pixel tags from monitoring your usage. You may also remove cookies
      stored from your computer or mobile device. However, do note that if you
      enable blocking of cookies and pixel tags, it may limit certain features
      and functions in your use of the Platform.
    </li>

    <h2>6.Other Websites</h2>
    <li>
      6.1.The Platform may contain links to other websites which are not
      maintained by us. This Privacy Policy only applies to the Platform. When
      visiting these third party websites, you should read their privacy
      policies which will apply to your use of those websites.
    </li>

    <h2>7.Protection of Personal Data</h2>
    <li>
      7.1.We wish to assure you that your Personal Data will be protected by
      high standards of security, and we will take all reasonable steps to keep
      secure any Personal Data we hold about you on secure servers.
    </li>
    <li>
      7.2.Notwithstanding our best efforts to protect your Personal Data, the
      nature of the Internet is such that data transmission over the Internet
      can never be fully secure. Hence, we cannot be responsible for events
      arising from unauthorised third parties gaining access to your Personal
      Data. It is solely your responsibility to maintain the secrecy of your
      user identities and password(s) at all times.
    </li>

    <h2>8.Retention, Access and Correction of Personal Data</h2>
    <li>
      8.1.Your Personal Data is retained as long as the purpose for which it was
      collected remains and until it is no longer necessary for any other legal
      or business purposes.
    </li>
    <li>
      8.2.You may request access or make corrections to your Personal Data held
      by us. We may charge a fee for processing your request for access. Such a
      fee depends on the nature and complexity of your access request.
    </li>
    <li>
      The GDPR also provides relevant individuals with additional rights
      including the right to obtain information on how we process your Personal
      Data, receive certain information provided in an electronic format and/or
      request that these be transmitted to a third party, request for your
      information to be erased, object or restrict the use or processing of your
      information in some circumstances. These will be subject to ongoing
      obligations imposed on us pursuant to any applicable law or regulation,
      and/or our legitimate reason or entitlement to continue processing your
      information, and/or to refuse that request.
    </li>

    <h2>9.Withdrawal of Consent</h2>
    <li>
      9.1.You may, at any time, withdraw your consent to our continued
      collection, use and disclosure of your Personal Data by contacting our
      data protection officer, the details of which are as set out in Paragraph
      12.1 below. If you decide that you do not want us to collect, use your
      personal data and/or to disclose your personal data to third parties, you
      understand that this may in some cases impact on the availability of the
      Products and/or Services that we may provide to you and may mean that the
      Products and/or Services provided to you (if possible at all) by us may
      not be customised for your use, may be less user-friendly and less
      efficient.
    </li>
    <li>
      9.2.Further, in withdrawing your consent, you acknowledge and agree that
      if we are no longer able to provide certain Products and/or Services to
      you as a consequence, we will be entitled to cease the provision of such
      Products and/or Services to you without incurring any liability on our
      part.
    </li>

    <h2>10.Amendments and Updates of this Privacy Policy</h2>
    <li>
      10.1.We may amend this Privacy Policy from time to time to ensure that
      this Privacy Policy is consistent with any developments to the way we use
      your Personal Data or any changes to the laws and regulations applicable
      to us. We will make available the updated policy on the App.
    </li>
    <li>
      10.2.All communications, transactions and dealings with us shall be
      subject to the latest version of this Privacy Policy in force at the time.
    </li>

    <h2>11.Governing Law and Dispute Resolution</h2>
    <li>
      11.1.Irrespective of the country from which you access or use our App or
      our Products and/or Services, to the extent permitted by law, this Privacy
      Policy shall be governed in accordance with the laws of Singapore without
      regard to choice or conflicts of law principles, and you hereby agree to
      submit to the exclusive jurisdiction of the courts of Singapore to resolve
      any claims or disputes which may arise in connection with this Privacy
      Policy. Any disputes concerning this Privacy Policy and claims concerning
      breach or inadequacy of Personal Data protection raised by any personnel
      from the European Union will be dealt with in accordance (to the maximum
      permissible extent) with the GDPR.
    </li>

    <h2>12.How to Contact Us</h2>
    <li>
      12.1.If you have any questions or comments about this Privacy Policy or
      encounter any technical issues with the App and/or Platform, please send
      your questions and feedback to us at our email,
      <a href="mailto:support@miraiele.com.sg">support@miraiele.com.sg</a>, or
      address, 105 Sims Avenue, #08-10, Chancerlodge Complex, Singapore 387429.
    </li>
  </div>
</template>

<script>
export default {
  name: "policy",
  data() {
    return {};
  },
};
</script>
<style scoped>
.box {
  min-height: 100vh;
  border: 1px solid #f0f0f0;
  padding: 10px 16px 20px;
  word-break: break-word;
  line-height: 1.6;
  color: #121212;
  box-sizing: border-box;
  text-align: left;
}
.box h1,
.box h2 {
  clear: left;
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 38px;
  line-height: 1.75;
  font-weight: 600;
  text-align: center;
}
.box h2 {
  font-size: 32px;
  text-align: left;
}
.box li {
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 28px;
  color: #333;
  list-style: none;
}
.box p {
  margin: 1em 0;
  line-height: 1.75;
  font-size: 28px;
  text-indent: 2em;
  color: #555;
}
.box p a,
.box li a {
  color: #1890ff;
}
.box p b {
  font-weight: 600;
  color: #000;
}
</style>
